<template>
  <div></div>
</template>

<script>
import mqtt from "./mqtt";
export default {
  data() {
    return {};
  },

  mounted() {
    this.connectMq();
  },
  methods: {
    connectMq() {
      // 连接选项
      const options = {
        connectTimeout: 4000, // 超时时间
        // 认证信息 按自己需求填写
        clientId: '12333333',
        username: "admin",
        password: "eanadmin",
      };
      const client = mqtt.connect("ws://192.168.0.102:15675/ws", options);

      client.on("reconnect", (error) => {
        console.log("正在重连:", error);
      });

      client.on("error", (error) => {
        console.log("连接失败:", error);
      });

      client.on("connect", (e) => {
        console.log("成功连接服务器111");
        //订阅一个主题
        client.subscribe("phone_" + phone, { qos: 0 }, function (err) {
          if (!err) {
            //client.publish('123', 'Hello mqtt')
            console.log("订阅成功");
          }
        });
      });
      //监听mq的返回
      client.on("message", function (topic, message, packet) {
        // message is Buffer
        console.log("packet", packet.payload.toString());
        client.end();
      });
    },
  },
};
</script>

<style scoped>
</style>
